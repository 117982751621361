<template>

  <!-- Лента пользователя -->
  <v-container class="h-100 unselectable" v-if="!isTransporter">

    <div class="py-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Что</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="grid-container pa-2">

      <div class="grid-item full-width full-color-block" v-if="isActiveOrder" @click="$router.push(`/requests`)">
        <div class="block-content">
          <div class="request-info">
            <div class="request-title">
              <p class="title is-5 ma-0" style="margin-bottom: 0 !important;">{{ clientOffersWrapper() }}</p>
            </div>
            <div v-if="counterOffersOfOrders !== 0 && this.activeOrderStatus !== 'agreed'" class="request-count">{{ counterOffersOfOrders }}</div>
          </div>
        </div>
      </div>

      <!-- Вторая строка: Профиль и Мои заказы -->
      <div class="grid-item half-width gradient-block-1" @click="$router.push('/profile')">
        <div class="block-content">
          <p class="title is-5">Профиль</p>
          <img src="/assets/avatar-profile.svg" alt="Профиль">
        </div>
      </div>

      <div class="grid-item half-width gradient-block-3" @click="$router.push('/orders')">
        <div class="block-content">
          <p class="title is-5">Мои заказы</p>
          <img src="/assets/list.svg" alt="Заказы">
        </div>
      </div>

      <div class="grid-item full-width" style="background: #e4f2f1;" v-if="!isActiveOrder" @click="$router.push('/main')">
        <div class="block-content">
          <div class="request-info">
            <div class="request-title">
              <p class="title is-5 ma-0" style="margin-bottom: 0 !important;">Создать заявку</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="haveRequestTransporter" @click="$router.push('/status-request-for-transporter')" class="grid-item full-width" style="background: #e4f2f1;">
        <div class="block-content">
          <div class="request-info">
            <div class="request-title">
              <p class="title is-5 ma-0" style="margin-bottom: 0 !important;">Заявка перевозчика</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="grid-item full-width" style="background: #e4f2f1;" @click="becomeCarrier">
        <div class="block-content">
          <div class="request-info">
            <div class="request-title">
              <p class="title is-5 ma-0" style="margin-bottom: 0 !important;">Стать перевозчиком</p>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-item full-width gradient-block-4" @click="$router.push('/documents')">
        <div class="block-content">
          <p class="title is-5 mb-3">Документы</p>
          <img
            style="max-width: 30%;"
            src="/assets/customer-service-worker-svgrepo-com.svg"
            alt="Документы">
        </div>
      </div>
    </div>

    <p class="text-center about-program" @click="toAboutProgramPage">О программе</p>

  </v-container>

  <!-- Лента перевозчика -->
  <v-container class="h-100 unselectable" v-else>

    <div class="py-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Кого</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="grid-container pa-2">

      <div class="grid-item full-width full-color-block-3" @click="$router.push('/balance')">
        <div class="block-content">
          <div class="request-info">
            <div class="request-title">
              <p class="title is-5 ma-0 mb-0 ml-2">Баланс</p>
            </div>
            <div class="balance-info" style="display: flex; align-items: center;">
              <div>{{ balance ? balance : 0 }}</div>
              <img src="/assets/who_coin.svg" class="ml-1" alt="Монетка" style="height: 1em; padding-bottom: 2px;">
            </div>
          </div>
        </div>
      </div>

      <div class="grid-item full-width full-color-block" @click="$router.push(`/orders/transporter/`)">
        <div class="block-content">
          <div class="request-info">
            <div class="request-title">
              <p class="title is-5 ma-0" style="margin-bottom: 0 !important;">Ваши перевозки</p>
            </div>
            <!-- Пример цифры откликов -->
            <div v-if="counterOrdersTransporter !== null" class="request-count">{{ counterOrdersTransporter }}</div>
          </div>
        </div>
      </div>

      <div class="grid-item full-width full-color-block-2" @click="$router.push(`/offers/`)">
        <div class="block-content">
          <div class="request-info">
            <div class="request-title">
              <p class="title is-5 ma-0" style="margin-bottom: 0 !important;">Ваши предложения</p>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-item half-width gradient-block-1" @click="$router.push('/profile')">
        <div class="block-content">
          <p class="title is-5">Профиль</p>
          <img src="/assets/avatar-profile.svg" alt="Профиль">
        </div>
      </div>

      <div class="grid-item half-width gradient-block-3" @click="$router.push('/orders/search')">
        <div class="block-content">
          <p class="title is-5">Поиск заявок</p>
          <img src="/assets/list.svg" alt="Поиск заявок">
        </div>
      </div>

      <div class="grid-item full-width gradient-block-2" @click="$router.push('/orders/history')">
        <div class="block-content">
          <p class="title is-5 mb-0">История</p>
          <img style="max-width: 50%;" src="/assets/delivery.svg" alt="История">
        </div>
      </div>

      <div class="grid-item full-width gradient-block-4" @click="$router.push('/documents')">
        <div class="block-content">
          <p class="title is-5 mb-3">Документы</p>
          <img
            style="max-width: 30%;"
            src="/assets/customer-service-worker-svgrepo-com.svg"
            alt="Документы">
        </div>
      </div>
    </div>

    <p class="text-center about-program" @click="toAboutProgramPage">О программе</p>

  </v-container>

</template>

<script>
export default {
  name: 'MainPage',
  data() {
    return {
    };
  },
  created() {
    if (!this.isTransporter) {
      this.$store.dispatch('GET_ORDERS');
      this.$store.dispatch('GET_ACTIVE_ORDER');
    } else {
      this.$store.dispatch('GET_ACTIVE_ORDERS_TRANSPORTER');
    }
  },
  computed: {
    activeOrderStatus() {
      return this.$store.getters.ACTIVE_ORDER.status;
    },
    isActiveOrder() {
      return this.$store.getters.IS_ACTIVE_ORDER;
    },
    counterOffersOfOrders() {
      return this.$store.getters.ACTIVE_ORDER_COUNTER;
    },
    counterOrdersTransporter() {
      return this.$store.getters.ACTIVE_ORDERS_TRANSPORTER_COUNTER;
    },
    isTransporter() {
      return this.$store.getters.ACCOUNT_TYPE == 1;
    },
    auth_type () {
      return this.$store.getters.AUTH_TYPE;
    },
    balance() {
      return this.$store.getters.BALANCE;
    },
    haveRequestTransporter() {
      return this.$store.getters.HAVE_REQUEST_TRANSPORTER === true ||
        this.$store.getters.HAVE_REQUEST_TRANSPORTER === 'true';
    }
  },
  methods: {
    clientOffersWrapper() {
      if (this.activeOrderStatus === 'agreed') {
        return 'Перевозка';
      } else {
        return this.counterOffersOfOrders !== 0 ? 'Отклики' : 'Нет откликов'
      }
    },
    toAboutProgramPage() {
      this.$router.push('/about-program');
    },
    becomeCarrier() {
      if (this.auth_type == 0) {
        this.$router.push('/login-transporter');
      } else {
        this.$router.push('/application-registration');
      }
    }
  },
};
</script>

<style scoped>
.request-info {
  display: flex;
  align-items: center; /* Выравнивание по вертикали */
}

.request-title {
  flex-grow: 1; /* Растягиваем блок на всё доступное пространство */
  text-align: center; /* Центрируем текст внутри блока */
}

.request-count {
  background-color: #ccc; /* Цвет квадратика */
  color: #fff;
  border-radius: 10px; /* Для создания круглого квадратика */
  display: flex;
  justify-content: center; /* Центрирование цифры внутри квадратика */
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.block-content {
  text-align: center;
}

.block-content img {
  max-width: 100%;
  height: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Два столбца */
  grid-gap: 20px; /* Пространство между карточками */
}

.grid-item {
    padding: 20px;
    font-size: 20px;
    text-align: center;

    border-radius: 20px;
    cursor: pointer;
}

.full-width {
  grid-column: span 2; /* Карточка на всю ширину */
}

.half-width {
  grid-column: span 1; /* Карточка на половину ширины */
}

/* Стили для обводки при выборе */
.grid-item.selected {
  outline: 2px solid #b5b5b5;
}

.gradient-block-1 {
    background: linear-gradient(-45deg, #E7F1E8, #E7F1E800);
}

.gradient-block-2 {
    background: linear-gradient(45deg, #E7ECF1, #E7ECF100);
}

.gradient-block-3 {
    background: linear-gradient(-135deg, #F1E7E7, #F1E7E700);
}

.gradient-block-4 {
    background: linear-gradient(135deg, #DDEFED, #DDEFED00);
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

.title.is-5 {
  font-size: 1.25rem !important;
}


@media screen and (max-width: 353px) {
  .title.is-5 {
    font-size: 1.1rem !important;
  }
}

@media screen and (max-width: 334px) {
  .title.is-5 {
    font-size: 1rem !important;
  }
}

.about-program {
  font-weight: 400px;
  font-size: 400;
  margin-top: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}

</style>
